import { createRoot } from "react-dom/client";
import { lazy, Suspense } from "react";
//import { kcContext as kcLoginThemeContext } from "./keycloak-theme/login/KcContext";
//import { kcContext as kcAccountThemeContext } from "./keycloak-theme/account/kcContext";
import { I18nextProvider } from "react-i18next";
import Myi18n from "./i18n";
// import reportWebVitals from './reportWebVitals';

//const KcLoginThemeApp = lazy(() => import("./keycloak-theme/login/KcApp"));
//const KcAccountThemeApp = lazy(() => import("./keycloak-theme/account/KcApp"));
const App = lazy(() => import("./apps/App"));

createRoot(document.getElementById("root")!).render(
  <>
    <I18nextProvider i18n={Myi18n}>
      <Suspense fallback={"Loading..."}>
        {(() => {
/*          if (kcLoginThemeContext !== undefined) {
            return <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
          }

          if (kcAccountThemeContext !== undefined) {
            return <KcAccountThemeApp kcContext={kcAccountThemeContext} />;
          }
*/

          return <App />;
        })()}
      </Suspense>
    </I18nextProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
